import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const Wrapper = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 40px;
    @media screen and (max-width: 600px) {
        padding: 0 18px;
    }
`

const StyledNavLink = styled(NavLink)`
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-family: 'Medium';
    font-weight: 500;
    text-align: center;
    display: inline-block;
}
`
export {
	Wrapper,
    StyledNavLink,
}