import styled from 'styled-components'

const Flex = styled.div`
    display: flex;
    text-align: center;
    gap: ${ props => props.gap + 'px' };
`
const FlexCenter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: ${ props => props.gap + 'px' };
`

const FlexBetween = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${ props => props.gap + 'px' };
`;

const FlexBetweenCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${ props => props.gap + 'px' };
`;

const FlexColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: ${ props => props.gap + 'px' };
`;

const FlexColumnStart = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: ${ props => props.gap + 'px' };
`;

export {
    Flex,
    FlexCenter,
    FlexBetweenCenter,
    FlexBetween,
    FlexColumn,
    FlexColumnStart
}