import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from "i18next";
import enUsTrans from "./locales/en-us.json";
import zhCnTrans from "./locales/zh-cn.json";
import {
  initReactI18next
} from 'react-i18next';

const languageDetector = new LanguageDetector();

i18n
.use(languageDetector) //嗅探当前浏览器语言
.use(initReactI18next) //init i18next
.init({
  //引入资源文件
  resources: {
    en: {
      translation: enUsTrans,
    },
    zh: {
      translation: zhCnTrans,
    },
  },
  //选择默认语言，选择内容为上述配置中的key，即en/zh
  fallbackLng: "zh",
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

// 语言更改时更新本地存储
// i18n.on('languageChanged', (lng) => {
//   localStorage.setItem('i18nextLng', lng);
//   // window.location.reload()
// });

export default i18n;
