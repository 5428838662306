import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination'; 
import 'swiper/css/autoplay'; 
import { Wrapper, StyledNavLink } from "../../components/style";
import { MainText } from "../../components/style/text";
import { HoveredImage } from "../../components/hover";
import Banner1 from "../../assets/banner1.png";
import Banner2 from "../../assets/banner2.png";
import home_hover1 from "../../assets/home_hover1.png";
import home_hover2 from "../../assets/home_hover2.png";
import home_hover3 from "../../assets/home_hover3.png";
import home_hover4 from "../../assets/home_hover4.png";
import home_hover5 from "../../assets/home_hover5.png";
import home_hover6 from "../../assets/home_hover6.png";
import openIcon from "../../assets/openIcon.png";
import { useTranslation } from 'react-i18next';
import 'animate.css';

const HomeBg = styled.div`
    height: 100%;
`
const BannerOne = styled.div`
    width: 100%;
    height: 600px;
    background-image: url(${Banner1});
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    @media screen and (max-width: 560px){
		height: 380px;
	}
`
const BannerTwo = styled.div`
    width: 100%;
    height: 600px;
    background-image: url(${Banner2});
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    @media screen and (max-width: 560px){
		height: 380px;
	}
`
const BannerText = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.4);
`
const Content = styled.div`
    position: relative;
    padding: 75px 0px;
    @media screen and (max-width: 560px){
		padding: 35px 0px;
        &.close {
            max-height: 480px;
            margin-bottom: 48px;
            overflow: hidden;
        }
	}
    
`
const ExpandBtn = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100%;
    @media screen and (min-width: 560px){
        display: none;
    }
`
const Filter = styled.div`
    height: 80px;
    background: linear-gradient(180deg, transparent 0, oklch(1 0 0) 100%);
`
const ExpandImg =styled.div`
    background-color: #fff;
`
const OpenIcon = styled.img`
    width: 40px;
    height: 28px;
    animation: fadeInDown ease-in 2s forwards;
    animation-iteration-count: infinite;
`
const ContentTitle = styled(MainText)`
    margin-bottom: 34px;
`
const ContentText = styled(MainText)`
    margin-bottom: 24px;
    @media screen and (max-width: 560px){
		margin-bottom: 12px;
	}
`
const FlexBetween = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
`;
const Item = styled.div`
    box-sizing: border-box;
    max-width: calc(33.33% - 20px);
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(33.33% - 20px);
    // height: 440px;
    aspect-ratio: 1 / 1;
    margin: 0 0 20px 20px;
    @media screen and (max-width: 1200px) {
        margin: 0 0 16px 20px;
        max-width: calc(50% - 20px);
        flex-basis: calc(50% - 16px);
    }
    @media screen and (max-width: 680px) {
        height: 300px;
        margin: 0 0 16px 20px;
        max-width: 100%;
        flex-basis: 100%;
    }
`;


// Install Swiper modules
function Home() {
    const { t } = useTranslation();
    const [expand, setExpand] = useState(false)

    const list = [
        {
            img: home_hover1,
            title: t('box_title1'),
            text: t('box_detail1')
        },
        {
            img: home_hover2,
            title: t('box_title2'),
            text: t('box_detail2')
        },
        {
            img: home_hover3,
            title: t('box_title3'),
            text: t('box_detail3')
        },
        {
            img: home_hover4,
            title: t('box_title4'),
            text: t('box_detail4')
        },
        {
            img: home_hover5,
            title: t('box_title5'),
            text: t('box_detail5')
        },
        {
            img: home_hover6,
            title: t('box_title6'),
            text: t('box_detail6')
        }
    ]
    return (
        <HomeBg>
            <Swiper
                modules={[Autoplay, Pagination]}
                spaceBetween={0} // 调整轮播项之间的间距
                slidesPerView={1} // 设置为 "auto"，以便每个 SwiperSlide 宽度充满整个屏幕
                autoplay={{
                  delay: 3000, // 自动轮播间隔时间，单位为毫秒
                  disableOnInteraction: false, // 允许手动滑动时中断自动轮播
                }}
                pagination={{ clickable: true }}
            >           
                <SwiperSlide style={{ width: '100%' }}>
                    <BannerOne>
                        <BannerText>
                            <MainText color={'#fff'} size={40} hsize={20} weight={600}>{t('bannertext_1')}</MainText>
                            <MainText color={'#fff'} size={40} hsize={20} weight={600}>{t('bannertext_2')}</MainText>
                        </BannerText>
                    </BannerOne>
                </SwiperSlide>
                <SwiperSlide style={{ width: '100%' }}>
                    <BannerTwo>
                        <BannerText>
                            <MainText color={'#fff'} size={40} hsize={20} weight={600}>{t('bannertext_3')}</MainText>
                            <MainText color={'#fff'} size={40} hsize={20} weight={600}>{t('bannertext_4')}</MainText>
                            <MainText color={'#fff'} size={40} hsize={20} weight={600}>{t('bannertext_5')}</MainText>
                        </BannerText>
                    </BannerTwo>
                </SwiperSlide>
            </Swiper>
            <Wrapper>
                <Content className={expand? 'open': 'close'}>
                    <ContentTitle color={'#000'} size={28} hsize={22} weight={700}>{t('liaojie')}</ContentTitle>
                    <ContentText color={'#000'} size={16} weight={700}  justify={true}>
                        {t('paragraph_1')}
                    </ContentText>
                    <ContentText color={'#000'} size={16} weight={700}  justify={true}>
                        {t('paragraph_2')}
                    </ContentText>
                    <ContentText color={'#000'} size={16} weight={700}  justify={true}>
                        {t('paragraph_3')}
                    </ContentText>
                    <ContentText color={'#000'} size={16} weight={700}  justify={true}>
                        {t('paragraph_4')}
                    </ContentText>
                    <ContentText color={'#000'} size={16} weight={700}  justify={true}>
                        {t('paragraph_5')}
                    </ContentText>
                    {!expand && 
                        <ExpandBtn>
                            <Filter />
                            <ExpandImg onClick={() => setExpand(true)}>
                                <OpenIcon className={'animate__fadeInDown'} src={openIcon} /> 
                            </ExpandImg>
                        </ExpandBtn>
                    }
                </Content>
                
                <FlexBetween>
                    {
                        list.map(item => (
                            <Item>
                                <HoveredImage
                                    src={item.img}
                                    alt="Description of the image"
                                    title={item.title}
                                    text={item.text}
                                />
                            </Item>
                        ))
                    }
                </FlexBetween>
            </Wrapper>
            <StyledNavLink to={'/more'}>
                <MainText color={'#5D5D5D'} size={18}  weight={700}>更多服务</MainText>
            </StyledNavLink>
        </HomeBg>
    )
}
export default Home