import React, { useEffect, useRef } from 'react';
import styled from 'styled-components'
import { Wrapper } from "../../components/style";
import { MaskImageText } from "../../components/hover";
import { FlexBetweenCenter, FlexBetween, FlexColumnStart } from "../../components/style/flex";
import { MainText } from "../../components/style/text";
import Banner from '../../components/banner'
import banner4 from "../../assets/banner4.png";
import strategy_img from "../../assets/strategy_img.png";
import about_img11 from "../../assets/about_img11.png";
import about_img3 from "../../assets/about_img3.png";
import about_img9 from "../../assets/about_img9.png";
import about_img6 from "../../assets/about_img6.png";
import about_img12 from "../../assets/about_img12.png";
import { useTranslation } from 'react-i18next';

const StrategyPage = styled.div`
    height: 100%;
`
const Content = styled(FlexBetweenCenter)`
    margin-top: 58px;
    margin-bottom: 62px;
    @media screen and (max-width: 1100px) {
        > *:nth-child(1) {
            display: none;
        }
    }
`
const Item = styled.div`
    flex: 1; 
    box-sizing: border-box;
`;
const ItemFlex = styled(FlexColumnStart)`
    flex: 1; 
    box-sizing: border-box;
`;
const ImgStyle = styled.img`
    width: 100%;
    height: 100%;
`
const Title = styled(MainText)`
    margin-bottom: 10px;
    padding-left: 63px;
    @media screen and (max-width: 560px) {
        padding-left: 30px;
    }
`
const ContentBox = styled.div`
    width: 100%;
    background: #F7F7F7;
    padding: 24px 32px 24px 63px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    box-sizing: border-box;
    &:hover {
        transform: translateY(-10px) scale(1.02); 
        box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.3); 
    }
    @media screen and (max-width: 560px) {
        padding: 24px 32px 24px 30px;
    }
`
const ContentTitle = styled(MainText)`
    margin-bottom: 12px;
`
const ContentText = styled(MainText)`
`
const FlexBetweenBottom = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
`
const HoverItem = styled.div`
    box-sizing: border-box;
    max-width: calc(50% - 20px);
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 20px);
    height: 220px;
    margin: 0 0 20px 20px;
    @media screen and (max-width: 860px) {
        height: 186px;
        margin: 0 0 16px 20px;
        max-width: 100%;
        flex-basis: 100%;
    }
    &:last-child {
        max-width: 100%;
        flex-basis: 100%;
    }
`;
function Strategy() {
    const { t } = useTranslation();

    const list = [
        { img: about_img11, title: t('ywtz'), text: t('ywtz_detail') },
        { img: about_img3, title: t('jscx'), text: t('jscx_detail') },
        { img: about_img9, title: t('tdkc'), text: t('tdkc_detail') },
        { img: about_img6, title: t('hzhbgx'), text: t('hzhbgx_detail') },
        { img: about_img12, title: t('pptg'), text: t('pptg_detail') },
    ]
    return(
        <StrategyPage>
            <Banner height={400} bgimg={banner4} text={t('zlfzgh')} />
            <Wrapper>
                <Content gap={20}>
                    <Item>
                        <ImgStyle src={strategy_img} />
                    </Item>
                    <ItemFlex gap={22}>
                        <Title color={'#000'} size={22}  weight={700}  justify={true}>{t('czmb')}</Title>
                        <ContentBox>
                            <ContentTitle color={'#000'} size={18}  weight={700}  justify={true}>{t('fwzyx')}</ContentTitle>
                            <ContentText color={'#5D5D5D'} size={16}  weight={700}  justify={true}>{t('fwzyx_detail')}</ContentText>
                        </ContentBox>
                        <ContentBox>
                            <ContentTitle color={'#000'} size={18}  weight={700}  justify={true}>{t('kfjckd')}</ContentTitle>
                            <ContentText color={'#5D5D5D'} size={16}  weight={700}  justify={true}>{t('kfjckd_detail')}</ContentText>
                        </ContentBox>
                        <ContentBox>
                            <ContentTitle color={'#000'} size={18}  weight={700}  justify={true}>{t('ywzz')}</ContentTitle>
                            <ContentText color={'#5D5D5D'} size={16}  weight={700}  justify={true}>{t('ywzz_detail')}</ContentText>
                        </ContentBox>
                        <ContentBox>
                            <ContentTitle color={'#000'} size={18}  weight={700}  justify={true}>{t('gjhfz')}</ContentTitle>
                            <ContentText color={'#5D5D5D'} size={16}  weight={700}  justify={true}>{t('gjhfz_detail')}</ContentText>
                        </ContentBox>
                    </ItemFlex>
                </Content>
                <FlexBetweenBottom>
                    {
                        list.map(item => (
                            <HoverItem>
                                <MaskImageText
                                    src={item.img}
                                    title={item.title}
                                    text={item.text}
                                />
                            </HoverItem>
                        ))
                    }
                </FlexBetweenBottom>
            </Wrapper>
        </StrategyPage>
    )
}

export default Strategy