import React, {useState, useContext, useEffect} from 'react';
import styled from 'styled-components'
import { ImgStyle } from "../style/image";
import { Wrapper, StyledNavLink } from "../style";
import { HeadText } from "../style/text";
import { FlexCenter, FlexColumn } from "../../components/style/flex";
import Logo from "../../assets/logo.png";
import open from "../../assets/open.png";
import enicon from "../../assets/en.png";
import zhicon from "../../assets/zh.png";
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../pages/languageContext';
import i18n from 'i18next';

const AppLogo = styled.img`
  height: 36px;
  pointer-events: none;
`
const AppHeader = styled.div`
    width: 100%;
    height: 115px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    @media screen and (max-width: 600px) {
        height: 85px;
        // position: fixed;
        // background-color: rgba(0, 0, 0);
    }
`
const AppIn = styled(Wrapper)`
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const FlexCenterBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 98px;
    
`
const LinkFlex = styled(FlexCenter)`
    @media screen and (max-width: 919px) {
        display: none;
    }
`
const OpenBtn = styled.div`
    display: flex;
    align-items: center;
    @media screen and (min-width: 920px) {
            display: none;
    }
`
const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    display: ${({ isOpen }) => (isOpen ? 'black' : 'none')};
    @media screen and (min-width: 920px) {
        display: none;
    }
`;
  const Content = styled.div`
    position: relative;
    z-index: 1;
    opacity: 1;
`;
const Sidebar = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: #000;
    width: 60%;
    transition: width 0.2s ease;
`;
const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${({ isOpen }) => (isOpen ? '0' : '-1')};
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    transition: opacity 0.2s ease;
`;
const LogoH5 = styled.div`
    padding: 40px 30px;
    margin-bottom: 30px;
`
const LogoImg = styled.img`
    width: 100%;
`

function App() {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const { language, changeLanguage } = useLanguage();

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    const closeSidebar = () => {
      setIsOpen(false);
    };

    const handleLanguageChange  = () => {
        const lng = language === 'zh'? 'en': 'zh';
        i18n.changeLanguage(lng)
        changeLanguage(lng)
    }

  return (
    <AppHeader>
        <AppIn>
            <StyledNavLink to={'/home'}>
                <AppLogo src={Logo} />
            </StyledNavLink>
            <FlexCenterBox>
                <LinkFlex gap={72}>
                    <StyledNavLink to={'/about'}>
                        {t('gyanzheng')}
                    </StyledNavLink>
                    <StyledNavLink to={'/strategy'}>
                        {t('zlfzgh')}
                    </StyledNavLink>
                    <StyledNavLink to={'/overview'}>
                        {t('lstdgk')}
                    </StyledNavLink>
                </LinkFlex>
                <FlexCenter gap={20}>
                    {
                        language === 'en'?
                            <ImgStyle className={'pointer'} src={zhicon} width={30} height={30} onClick={handleLanguageChange }/>
                            :
                            <ImgStyle className={'pointer'} src={enicon} width={30} height={30} onClick={handleLanguageChange }/>
                    }
                    <OpenBtn className={'openContainer'} >
                        <ImgStyle src={open} width={30} height={30} onClick={toggleSidebar}/>
                    </OpenBtn>
                </FlexCenter>
            </FlexCenterBox>
        </AppIn>
        <Container isOpen={isOpen}>
            <Content>
                <Sidebar onClick={toggleSidebar}>
                    <LogoH5>
                        <LogoImg src={Logo} />
                    </LogoH5>
                    <FlexColumn gap={30}>
                        <StyledNavLink to={'/home'}>
                            <HeadText size={14} color={'#fff'}>{t('shouye')}</HeadText>
                        </StyledNavLink>
                        <StyledNavLink to={'/about'}>
                            <HeadText size={14} color={'#fff'}>{t('gyanzheng')}</HeadText>
                        </StyledNavLink>
                        <StyledNavLink to={'/strategy'}>
                            <HeadText size={14} color={'#fff'}>{t('zlfzgh')}</HeadText>
                        </StyledNavLink>
                        <StyledNavLink to={'/overview'}>
                            <HeadText size={14} color={'#fff'}>{t('lstdgk')}</HeadText>
                        </StyledNavLink>
                    </FlexColumn>
                </Sidebar>
            </Content>
            <Overlay isOpen={isOpen} onClick={closeSidebar} />
        </Container>
        
    </AppHeader>
  );
}

export default App;