import React from 'react';
import styled from 'styled-components'
import { Wrapper } from "../style";
import { MainText } from "../style/text";

const BannerBg = styled.div`
    width: 100%;
    height: ${ props => props.height + 'px' };
    background-image: url(${ props => props.bgimg });
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    @media screen and (max-width: 560px) {
        height: 320px;
    }
`
const BannerMask = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
`
const Content = styled(Wrapper)`
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
`

function Banner({ height, bgimg, text }) {
    return(
        <BannerBg height={height} bgimg={bgimg}>
            <BannerMask>
                <Content>
                    <MainText color={'#fff'} size={40} hsize={22} justify={true} weight={800}>
                        {text}
                    </MainText>
                </Content>
            </BannerMask>
        </BannerBg>
    )
}

export default Banner