import React from 'react';
import styled from 'styled-components'
import { MainText, FootText } from "../style/text";
import { Flex, FlexBetweenCenter, FlexColumnStart } from "../style/flex";
import { Wrapper } from "../style";
import { useTranslation } from 'react-i18next';
import Logo from "../../assets/logo.png";
import qrCode from "../../assets/qrCode.png";
import jscj from "../../assets/logo/jscj.png";
import slowmist from "../../assets/logo/slowmist.png";
// import zwlssws from "../../assets/logo/zwlssws.png";
import bianlive from "../../assets/logo/bianlive.png";
import panews from "../../assets/logo/panews.jpg";
import xqrb from "../../assets/logo/xqrb.png";
import daling from "../../assets/logo/daling.jpg";
import coinlive from "../../assets/logo/coinlive.jpg";
import nftscan from "../../assets/logo/nftscan.png";
import catcher from "../../assets/logo/catcher.png";
import tokenpocket from "../../assets/logo/tokenpocket.png";
import { goLink } from "../../utils/link";

const FooterBg = styled.div`
    background: #000;

`
const PageWrapper = styled(Wrapper)`
    padding: 65px 40px 96px;
    gap: 102px;
`
const FlexStyled = styled(FlexBetweenCenter)`
    margin-top: 74px;
    flex-wrap: wrap;
    gap: 40px;
    @media screen and (max-width: 509px) {
        justify-content: center;
    }
`
const Title = styled(MainText)`
    margin-bottom: 28px;
`

const ImgStyle = styled.img`
    cursor: pointer;
    width: auto;
    height: 26px;
    @media screen and (max-width: 560px) {
        height: 18px;
    }
`
const LogoStyle = styled.img`
    cursor: pointer;
    width: auto;
    height: ${({width}) => width + 'px'};
`
const FlexBetween = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 28px;
    align-items: baseline;
`;

const Flexbox = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 28px;
    align-items: baseline;
`;

const HzhbFlexBetween = styled(Flexbox)`
    gap: 40px;
    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
`

function Footer() {
    const { t } = useTranslation();

    return(
        <FooterBg>
            <PageWrapper>
                 <Flex>
                    <div>
                        <Title color={'#fff'} size={16} weight={700} justify={true}>{t('partner')}</Title>
                        <HzhbFlexBetween>
                            <FlexColumnStart gap={20}>
                                <FootText color={'#fff'} size={14} weight={300} justify={true}>{t('web3.0')}</FootText>
                                <Flexbox>
                                    <ImgStyle src={slowmist}/>
                                    <ImgStyle onClick={() => goLink('binanceLive')} src={bianlive}/>
                                    <ImgStyle src={nftscan}/>
                                    <ImgStyle src={daling}/>
                                    <ImgStyle src={tokenpocket}/>
                                </Flexbox>
                            </FlexColumnStart>
                            {/* <FlexColumnStart gap={20}>
                                <FootText color={'#fff'} size={14} weight={300} justify={true}>{t('lawFirms')}</FootText>
                                <ImgStyle height={30} src={zwlssws}/>
                            </FlexColumnStart> */}
                            <FlexColumnStart gap={20}>
                                <FootText color={'#fff'} size={14} weight={300} justify={true}>{t('media')}</FootText>
                                <Flexbox>
                                    <ImgStyle src={catcher}/>
                                    <ImgStyle src={panews}/>
                                    <ImgStyle src={xqrb}/>
                                    <ImgStyle src={jscj}/>
                                    <ImgStyle src={coinlive}/>
                                </Flexbox>
                            </FlexColumnStart>
                        </HzhbFlexBetween>
                    </div>
                </Flex>
                <FlexBetween>
                    <div>
                        <Title color={'#fff'} size={16} weight={700} justify={true}>{t('core')}</Title>
                        <FlexColumnStart gap={20}>
                            <FootText color={'#fff'} size={14} weight={300} justify={true}>{t('news')}</FootText>
                            <FootText color={'#fff'} size={14} weight={300} justify={true}>{t('statute')}</FootText>
                            <FootText color={'#fff'} size={14} weight={300} justify={true}>{t('opinion')}</FootText>
                        </FlexColumnStart>
                    </div>
                    <div>
                        <Title color={'#fff'} size={16} weight={700} justify={true}>{t('mechanism')}</Title>
                        <FlexColumnStart gap={20}>
                            <FootText color={'#fff'} size={14} weight={300} justify={true}>{t('finance')}</FootText>
                            <FootText color={'#fff'} size={14} weight={300} justify={true}>{t('crypto')}</FootText>
                        </FlexColumnStart>
                    </div>
                    <div>
                        <Title color={'#fff'} size={16} weight={700} justify={true}>{t('contact')}</Title>
                        <FlexColumnStart gap={20}>
                            <FootText color={'#fff'} size={14} weight={300} justify={true}>{t('advantage')}</FootText>
                        </FlexColumnStart>
                    </div>
                </FlexBetween>
                
                <FlexStyled>
                    <LogoStyle height={58} src={Logo} />
                    <LogoStyle height={80} src={qrCode} />
                </FlexStyled>
            </PageWrapper>
        </FooterBg>
        
    )
}

export default Footer