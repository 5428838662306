import { Routes, Route } from 'react-router-dom'
import { LanguageProvider } from './languageContext';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import Header from "../components/header";
import Home from "./home";
import More from "./more";
import About from './about';
import Strategy from './strategy';
import Overview from './overview';
import Bottom from "../components/pageBottom";
import Footer from "../components/footer";
import '../i18n.js'

const WrapperApp = styled.div`
height: 100%;
`

function App() {

  return (
    <LanguageProvider>
      <WrapperApp>
        <Header />
        <div>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/more" element={<More />} />
            <Route path="/about" element={<About />} />
            <Route path="/strategy" element={<Strategy />} />
            <Route path="/overview" element={<Overview />} />
            <Route path="/" element={<Home />}/>
          </Routes>
        </div>
        <Bottom />
        <Footer />
      </WrapperApp>
    </LanguageProvider>
  );
}

export default App;
