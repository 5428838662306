// import { rem } from '@/theme'
import styled from 'styled-components'

const lineHeightList = {
    12: 20,
    13: 20,
    14: 22,
    15: 24,
    16: 34,
    18: 28,
    20: 34,
    22: 35,
    24: 38,
    26: 42,
    28: 30,
    30: 48,
    32: 38,
    36: 44,
    40: 58,
    44: 52,
    48: 58,
    54: 54,
    56: 68
}
const TextStyle = styled.div `
	color: ${ props => props.color };
	${ props => props.justify && 'text-align: justify'};
	font-size: ${ props => props.size + 'px'};
	line-height: ${ props => lineHeightList[props.size] + 'px'};
	font-weight: ${ props => props.weight?? 500};
	// letter-spacing: ${ props => props.spac? props.spac + 'rem': '0.1rem'};
	// word-break: break-all;
	@media screen and (max-width: 780px){
		font-size: ${ ({size, hsize}) => hsize? hsize + 'px': size + 'px'};
		line-height: ${ props => lineHeightList[props.hsize || props.size] + 'px'};
		// letter-spacing: 0.1rem;
	}
`
const HeadText = styled(TextStyle)
`
	font-family: 'Medium';
`
const MainText = styled(TextStyle)
`
	font-family: 'Bold';
`
const FootText = styled(TextStyle)
`
	font-family: 'Light';
`

const TabTextStyle = styled(TextStyle)
`
    padding: 8px 0px;
    margin: 12px 0 16px;
	@media screen and (max-width: 540px){
		margin: 0 0 12px;
	}
`

export {
    TextStyle,
    HeadText,
    MainText,
    FootText,
    TabTextStyle
}