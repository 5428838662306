import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import { Wrapper, StyledNavLink } from "../../components/style";
import { MainText } from "../../components/style/text";
import { MaskImage, HoveredImage, MaskImageText } from "../../components/hover";
import { FlexBetweenCenter } from "../../components/style/flex";
import Banner from '../../components/banner'
import banner3 from "../../assets/banner3.png";
import mask_img1 from "../../assets/mask_img1.png";
import mask_img2 from "../../assets/mask_img2.png";
import about_hover1 from "../../assets/about_hover1.png";
import about_hover2 from "../../assets/about_hover2.png";
import about_hover3 from "../../assets/about_hover3.png";
import about_hover4 from "../../assets/about_hover4.png";
import about_hover5 from "../../assets/about_hover5.png";
import about_hover6 from "../../assets/about_hover6.png";
import about_img1 from "../../assets/about_img1.png";
import about_img2 from "../../assets/about_img2.png";
import about_img3 from "../../assets/about_img3.png";
import about_img4 from "../../assets/about_img4.png";
import about_img5 from "../../assets/about_img5.png";
import about_img6 from "../../assets/about_img6.png";
import about_img7 from "../../assets/about_img7.png";
import about_img8 from "../../assets/about_img8.png";
import about_img9 from "../../assets/about_img9.png";
import about_img10 from "../../assets/about_img10.png";
import openIcon from "../../assets/openIcon.png";
import { useTranslation } from 'react-i18next';
import 'animate.css';

const AboutPage = styled.div`
    height: 100%;
`
const Content = styled.div`
    position: relative;
    padding: 50px 0px 92px;
    @media screen and (max-width: 560px){
		padding: 40px 0px 62px;
        &.close {
            max-height: 450px;
            margin-bottom: 48px;
            overflow: hidden;
        }
	}
`
const ExpandBtn = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100%;
    @media screen and (min-width: 560px){
        display: none;
    }
`
const Filter = styled.div`
    height: 80px;
    background: linear-gradient(180deg, transparent 0, oklch(1 0 0) 100%);
`
const ExpandImg =styled.div`
    background-color: #fff;
`
const OpenIcon = styled.img`
    width: 40px;
    height: 28px;
    animation: fadeInDown ease-in 2s forwards;
    animation-iteration-count: infinite;
`
const ContentTitle = styled(MainText)`
    margin-bottom: 36px;
`
const ContentText = styled(MainText)`
    margin-bottom: 24px;\
    @media screen and (max-width: 560px){
		margin-bottom: 10px;
	}
`
const Item = styled.div`
    box-sizing: border-box;
    max-width: calc(50% - 20px);
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 20px);
    height: 396px;
    margin: 0 0 20px 20px;
    @media screen and (max-width: 860px) {
        height: 266px;
        margin: 0 0 16px 20px;
        max-width: 100%;
        flex-basis: 100%;
    }
`;
const ImgItem = styled.div`
    flex: 1 1 0%;
    box-sizing: border-box;
`
const StyleDetail = styled(FlexBetweenCenter)`
    background: #F7F7F7;
    height: 100%;
    padding: 0 53px;
    @media screen and (max-width: 1100px) {
        box-sizing: border-box;
        padding: 10px;
    }
`
const VulueTitle = styled.div`
    padding: 72px 0 23px;
`
const VulueDetail = styled.div`
    margin-bottom: ${props => props.bottom + 'px'};
`
const FlexBetween = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1100px) {
        &.noreverse{
            display: block;
        }
        &.reverse{
            flex-direction: column-reverse;
            justify-content: center;
        }
    }
`;
const HoverFlexBetween = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
`;
const FlexBetweenBottom = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
`
const HoverItem = styled.div`
    box-sizing: border-box;
    max-width: calc(50% - 20px);
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 20px);
    height: 220px;
    margin: 0 0 20px 20px;
    @media screen and (max-width: 860px) {
        height: 186px;
        margin: 0 0 16px 20px;
        max-width: 100%;
        flex-basis: 100%;
    }
`;

function About() {
    const { t } = useTranslation();
    const [expand, setExpand] = useState(false)

    const list1 = [
        {
            img: about_hover1,
            title: t('persistence'),
            text: t('persistence_d')
        },
        {
            img: about_hover2,
            title: t('customerFirst'),
            text: t('customerFirst_d')
        },
        {
            img: about_hover3,
            title: t('innovation'),
            text: t('innovation_d')
        },
        {
            img: about_hover4,
            title: t('integrity'),
            text: t('integrity_d')
        },
        {
            img: about_hover5,
            title: t('teamWork'),
            text: t('teamWork_d')
        },
        {
            img: about_hover6,
            title: t('learning'),
            text: t('learning_d')
        },
    ]
    const list2 = [
        { img: about_img1, title: t('encrypted'), text: t('encrypted_detail') },
        { img: about_img2, title: t('tradingPlatform'), text: t('tradingPlatform_detail') },
        { img: about_img3, title: t('encryptedTechnology'), text: t('encryptedTechnology_detail') },
        { img: about_img4, title: t('jmtzjj'), text: t('jmtzjj_detail') },
        { img: about_img5, title: t('jmkq'), text: t('jmkq_detail') },
        { img: about_img6, title: t('kjzfhkfw'), text: t('kjzfhkfw_detail') },
        { img: about_img7, title: t('tgfw'), text: t('tgfw_detail') },
        { img: about_img8, title: t('znhtzxh'), text: t('znhtzxh_detail') },
        { img: about_img9, title: t('nftip'), text: t('nftip_detail') },
        { img: about_img10, title: t('fljgzfjg'), text: t('fljgzfjg_detail') },
    ]
    return (
        <AboutPage>
            <Banner height={400} bgimg={banner3} text={t('gyanzheng')} />
            <Wrapper>
                <Content className={expand? 'open': 'close'}>
                    <ContentTitle color={'#000'} size={28} hsize={22} weight={700}>{t('companyProfile')}</ContentTitle>
                    <ContentText color={'#000'} size={16} weight={700}  justify={true}>
                        {t('profile_paragraph_1')}
                    </ContentText>
                    <ContentText color={'#000'} size={16} weight={700}  justify={true}>
                        {t('profile_paragraph_2')}
                    </ContentText>
                    <ContentText color={'#000'} size={16} weight={700}  justify={true}>
                        {t('profile_paragraph_3')}
                    </ContentText>
                    <ContentText color={'#000'} size={16} weight={700}  justify={true}>
                        {t('profile_paragraph_4')}
                    </ContentText>
                    {!expand && 
                        <ExpandBtn>
                            <Filter />
                            <ExpandImg onClick={() => setExpand(true)}>
                                <OpenIcon className={'animate__fadeInDown'} src={openIcon} /> 
                            </ExpandImg>
                        </ExpandBtn>
                    }
                </Content>
                <FlexBetween className='noreverse'>
                    <ImgItem>
                        <MaskImage 
                            src={mask_img1}
                            alt="image"
                            title={t('mission')}
                            lineW={70}
                            textSize={34}
                            LineGap={40}
                        />
                    </ImgItem>
                    <ImgItem>
                        <StyleDetail>
                            <MainText color={'#5D5D5D'} size={16}  weight={700}  justify={true}>
                                {t('mission_detail')}
                            </MainText>
                        </StyleDetail>
                    </ImgItem>
                </FlexBetween>
                <FlexBetween className='reverse'>
                    <ImgItem>
                        <StyleDetail>
                            <MainText color={'#5D5D5D'} size={16}  weight={700}  justify={true}>
                                {t('vision_detail')}
                            </MainText>
                        </StyleDetail>
                    </ImgItem>
                    <ImgItem>
                        <MaskImage 
                            src={mask_img2}
                            alt="image"
                            title={t('vision')}
                            lineW={70}
                            textSize={34}
                            LineGap={40}
                        />
                    </ImgItem>
                </FlexBetween>
                <VulueTitle>
                    <MainText color={'#000'} size={28} hsize={22} weight={700}>
                        {t('values')}
                    </MainText>
                </VulueTitle>
                <VulueDetail bottom={50}>
                    <MainText color={'#5D5D5D'} size={16} weight={700}>
                        {t('values_detail')}
                    </MainText>
                </VulueDetail>
                <HoverFlexBetween>
                    {
                        list1.map(item =>(
                            <Item>
                                <HoveredImage
                                    src={item.img}
                                    title={item.title}
                                    text={item.text}
                                    lineW={26}
                                    hoverTitle={false}
                                />
                            </Item>
                        ))
                    }
                </HoverFlexBetween>
                <VulueTitle>
                    <MainText color={'#000'} size={28} hsize={22} weight={700}>
                        {t('serve')}
                    </MainText>
                </VulueTitle>
                <VulueDetail bottom={72}>
                    <MainText color={'#5D5D5D'} size={16} weight={700}>
                        {t('serve_detail1')}
                    </MainText>
                    <MainText color={'#5D5D5D'} size={16} weight={700}>
                        {t('serve_detail2')}
                    </MainText>
                </VulueDetail>
                <FlexBetweenBottom>
                    {
                        list2.map(item => (
                            <HoverItem>
                                <MaskImageText
                                    src={item.img}
                                    title={item.title}
                                    text={item.text}
                                />
                            </HoverItem>
                        ))
                    }
                </FlexBetweenBottom>
            </Wrapper>
        </AboutPage>
    )
}
export default About