const links = {
    'slowmist': 'https://cn.slowmist.com/',
    'binanceLive': 'https://www.binance.com/zh-CN/feed/profile/anzheng_lawservicecenter',
    'zhongweng': 'https://www.zhongwenlaw.com/en/',
    'jinse': 'https://www.jinse.cn/',
    'fanews': 'https://www.panewslab.com/brandingassets',
    'xqrb': ''
}

export function goLink(text){
    let link = links[text]
    if(link) {
        window.open( link, '_blank')
    }
}