import React, { useEffect, useRef } from 'react';
import styled from 'styled-components'
import { Wrapper } from "../../components/style";
import { MaskImageText } from "../../components/hover";
import { FlexBetweenCenter, FlexBetween, FlexColumnStart } from "../../components/style/flex";
import { MainText } from "../../components/style/text";
import Banner from '../../components/banner'
import banner5 from "../../assets/banner5.png";
import { useTranslation } from 'react-i18next';

const OverviewPage = styled.div`
    height: 100%;
`
const VulueTitle = styled.div`
    padding: 72px 0 23px;
`
const VulueDetail = styled.div`
    margin-bottom: ${props => props.bottom + 'px'};
`
function Overview() {
    const { t } = useTranslation();

    return(
        <OverviewPage>
            <Banner height={400} bgimg={banner5} text={t('lstdgk')} />
            <Wrapper>
                <VulueTitle>
                    <MainText color={'#000'} size={28} hsize={22} weight={700}>
                        {t('lstdgk')}
                    </MainText>
                </VulueTitle>
                <VulueDetail bottom={50}>
                    <MainText color={'#5D5D5D'} size={16} weight={700} justify={true}>
                        {t('lstdgk_detail')}
                    </MainText>
                </VulueDetail>
            </Wrapper>
        </OverviewPage>
    )
}

export default Overview