import React from 'react';
import styled from 'styled-components'
import { MainText } from "../style/text";
import { FlexCenter } from "../style/flex";
import { ImgStyle } from "../style/image";
import { useTranslation } from 'react-i18next';
import goContact from '../../assets/goContact.png'

const Wrapper = styled.div`
    width: 100%;
    padding: 160px 0 107px;
    @media screen and (max-width: 560px){
		padding: 80px 0 66px;
	}
`
const FlexCenterStyle = styled(FlexCenter)`

`
const We = styled.div`
    margin-top: 24px;
    display: inline-block;
    cursor: pointer;
`
function Bottom() {
    const { t } = useTranslation();

    const goLink = () => {
        window.open('https://hk.mikecrm.com/IRVCvTR', '_blank')
    }
    return(
        <Wrapper>
            <MainText color={'#000'} size={30} weight={700} spac={0.56}>
                {t('help')}
            </MainText>
            <We>
                <FlexCenterStyle gap={20} onClick={() => goLink()}>
                    <MainText color={'#5D5D5D'} size={18} weight={700}>
                        {t('contact')}
                    </MainText>
                    <ImgStyle width={21} height={24} src={goContact} alt={''} />
                </FlexCenterStyle>
            </We>
        </Wrapper>
    )
}

export default Bottom