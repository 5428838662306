import React, { createContext, useState, useContext, useEffect } from 'react';

const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    // 从本地存储中获取语言设置，如果不存在则默认为英文
    return localStorage.getItem('i18nextLng') || 'en';
  });

  useEffect(() => {
    // 将语言设置存储到本地存储中
    localStorage.setItem('i18nextLng', language);
  }, [language]);

  const changeLanguage = (lng) => {
    setLanguage(lng);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};