import React, { useEffect, useRef } from 'react';
import styled from 'styled-components'
import { Wrapper } from "../../components/style";
import { HoveredImage } from "../../components/hover";
import { MainText } from "../../components/style/text";
import Banner from '../../components/banner'
import banner6 from "../../assets/banner6.png";
import more_hover1 from "../../assets/more_hover1.png";
import more_hover2 from "../../assets/more_hover2.png";
import more_hover3 from "../../assets/more_hover3.png";
import more_hover4 from "../../assets/more_hover4.png";
import more_hover5 from "../../assets/more_hover5.png";
import more_hover6 from "../../assets/more_hover6.png";
import more_hover7 from "../../assets/more_hover7.png";
import more_hover8 from "../../assets/more_hover8.png";
import more_hover9 from "../../assets/more_hover9.png";
import more_hover10 from "../../assets/more_hover10.png";
import more_hover11 from "../../assets/more_hover11.png";
import more_hover12 from "../../assets/more_hover12.png";
import { useTranslation } from 'react-i18next';

const OverviewPage = styled.div`
    height: 100%;
`
const Content = styled(Wrapper)`
    margin-top: 75px;
`
const FlexBetween = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
`;
const Item = styled.div`
    box-sizing: border-box;
    max-width: calc(33.33% - 20px);
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(33.33% - 20px);
    // height: 250px;
    aspect-ratio: 1.666 / 1;
    margin: 0 0 20px 20px;
    @media screen and (max-width: 1200px) {
        margin: 0 0 16px 16px;
        max-width: calc(50% - 16px);
        flex-basis: calc(50% - 16px);
    }
    @media screen and (max-width: 860px) {
        margin: 0 0 16px 20px;
        max-width: 100%;
        flex-basis: 100%;
    }
`;
function Overview() {
    const { t } = useTranslation();

    const list = [
        {
            img: more_hover1,
            title: t('yhdk'),
            text: t('yhdk_d')
        },
        {
            img: more_hover2,
            title: t('db'),
            text: t('db_d')
        },
        {
            img: more_hover12,
            title: t('sbjiufen'),
            text: t('sbjiufen_d')
        },
        {
            img: more_hover3,
            title: t('hgzx'),
            text: t('hgzx_d')
        },
        {
            img: more_hover4,
            title: t('szzcjy'),
            text: t('szzcjy_d')
        },
        {
            img: more_hover5,
            title: t('qklflzc'),
            text: t('qklflzc_d')
        },
        {
            img: more_hover6,
            title: t('szzctg'),
            text: t('szzctg_d')
        },
        {
            img: more_hover7,
            title: t('aml'),
            title2: t('kyc'),
            text: t('amlkyc_d')
        },
        {
            img: more_hover11,
            title: t('nftly'),
            text: t('nftly_d')
        },
        {
            img: more_hover8,
            title: t('plpxjy'),
            text: t('plpxjy_d')
        },
        {
            img: more_hover9,
            title: t('jiufen'),
            text: t('jiufen_d')
        },
        {
            img: more_hover10,
            title: t('tzfw'),
            text: t('tzfw_d')
        },
    ]
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    return(
        <OverviewPage>
            <Banner height={600} bgimg={banner6} text={t('wmtgnxfw')} />
            <Content>
                <FlexBetween>
                    {
                        list.map(item => (
                            <Item>
                                <HoveredImage
                                    src={item.img}
                                    title={item.title}
                                    title2={item.title2? item.title2 : false}
                                    text={item.text}
                                    lineW={34}
                                    titleSize={20}
                                    hoverTitle={false}
                                />
                            </Item>
                        ))
                    }
                </FlexBetween>
            </Content>
        </OverviewPage>
    )
}

export default Overview