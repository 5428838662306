import React from 'react';
import styled from 'styled-components';
import { FlexColumn, FlexColumnStart } from "../style/flex";
import { MainText } from "../style/text";

const HoverBox = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #D6BB95;
    color: #fff;
    opacity: 0;
    box-sizing: border-box;
    transition: opacity 0.2s ease;
`;
const HoverBoxIn = styled.div`
    overflow-y: auto;
    height: 100%;
`
const ImageContainer = styled.div`
    min-width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    &:hover ${HoverBox} {
        opacity: 1;
    }
  }
`;
const StyleFlexColumn = styled(FlexColumn)`
    width:100%;
    margin-bottom: 14%;
    box-sizing: border-box;
    @media screen and (max-width: 780px){
        margin-bottom: 2%;
	}
`
const MaskImageContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
`;
const MaskImageTransform = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    .ImageWithHoverText {
        transition: transform .7s ease-in-out;
    }
    &:hover {
        .ImageWithHoverText {
          transform: scale(1.3); 
        }
    }
`;

const ImageWithHoverText = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const Mask = styled(FlexColumn)`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.72);
`
const MaskText = styled(FlexColumnStart)`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.72);
    @media screen and (max-width: 560px){
        .title{
            font-size: 14px;
        }
	}
`
const MaskTextIn = styled.div`
    overflow-y: auto;
    height: 100%;
    padding: 20px 0;
`
const Line = styled.div`
    max-width: 80%;
    padding: 30px 20px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    @media screen and (max-width: 560px){
        padding: 10px 20px;
	}
`
const HoverLine = styled.div`
    max-width: 80%;
    padding: 20px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    margin-top: 20px;
    @media screen and (max-width: 560px){
        padding: 10px;
        // border: none
	}
`

const HoverText = styled(MainText)`
    padding: 2% 8%;
    @media screen and (max-width: 560px){
        padding: 10px 
    }
`
const FlexColumnBox = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 100%;
`

const StyleText = styled(MainText)`
    padding-left: 50px;
    padding-right: 30px;
    @media screen and (max-width: 540px){
		font-size: 12px;
        letter-spacing: 0.1rem;
        line-height: 26px;
        padding: 0 20px;
	}
`
const StyleTitle = styled(MainText)`
    padding-left: 50px;
    padding-right: 30px;
    margin-bottom: 20px;
    @media screen and (max-width: 540px){
		font-size: 12px;
        letter-spacing: 0.1rem;
        line-height: 26px;
        padding: 0 20px;
        margin-bottom: 10px;
	}
`
// React组件
const HoveredImage = ({ 
    src,
    alt,
    title,
    title2=null,
    text,
    titleSize = 32,
    textSize = 18,
    hoverTitle = true
}) => {
  return (
    <ImageContainer>
        <ImageWithHoverText src={src} alt={alt} />
        <Mask gap={25}>
            <Line>
                <MainText color={'#fff'} size={titleSize} hsize={20} weight={700} spac={0.4}>
                    {title}
                    {title2&& (
                        <>
                            <br/>
                            {title2}
                        </>
                    )}
                </MainText>
            </Line>
        </Mask>
        <HoverBox>
            <HoverBoxIn>
                <FlexColumnBox>
                    { hoverTitle && (
                        <StyleFlexColumn gap={42}>
                            <HoverLine>
                                <MainText color={'#fff'} size={titleSize} hsize={20} weight={700} spac={0.4}>
                                    {title}
                                </MainText>
                            </HoverLine>
                        </StyleFlexColumn>
                    )}
                    <HoverText color={'#fff'} size={textSize} hsize={16} weight={700} spac={0.2} justify={true}>
                        {text}
                    </HoverText>
                </FlexColumnBox>
            </HoverBoxIn>
        </HoverBox>
    </ImageContainer>
  );
};

const MaskImage = ({ src, alt, title, textSize, LineGap }) => {
  return (
    <MaskImageContainer>
        <ImageWithHoverText src={src} alt={alt} />
        <Mask gap={LineGap}>
            <Line>
            <MainText color={'#fff'} size={textSize} hsize={20} weight={700} spac={0.4}>
                {title}
            </MainText>
            </Line>
        </Mask>
    </MaskImageContainer>
  );
};

const MaskImageText = ({ 
    src,
    alt,
    title,
    titleSize = 18,
    text,
    textSize = 16,
    LineGap = 10,
    LineGapH5 = 0,
    pl=10,
    pr=8
}) => {
  return (
    <MaskImageTransform>
        <ImageWithHoverText
            src={src}
            alt={alt}
            className="ImageWithHoverText"
        />
        <MaskText gap={LineGap} gapH5={LineGapH5}>
            <MaskTextIn>
                <FlexColumnBox>
                    <StyleTitle className={'title'} color={'#fff'} size={titleSize} hsize={16} weight={700} spac={0.4} justify={true}>
                        {title}
                    </StyleTitle>
                    <StyleText color={'#fff'} size={textSize} hsize={14} weight={700} spac={0.4} justify={true}>
                        {text}
                    </StyleText>
                </FlexColumnBox>
            </MaskTextIn>
        </MaskText>
    </MaskImageTransform>
  );
};

export {
    HoveredImage,
    MaskImage,
    MaskImageText
}
